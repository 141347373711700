import { createApp, h } from 'vue';
import App from './App.vue';
import 'maz-ui/css/main.css';
import router from './router';
import store from './store';
import pinia from '@/plugins/pinia';
import i18n from './locales/index';
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import { mobile, desktop } from '@/directives/ScreenDirective';
import ClickOutsideDirective from '@/directives/ClickOutsideDirective';
// @ts-ignore
import VueAppleLogin from 'vue-apple-login';
import '@formkit/themes/genesis';
// @ts-ignore
import { setupApollo } from '@/plugins/apollo';
// @ts-ignore
import ReadMore from 'vue-read-more';
import { toasterOptions, installToaster } from '@/plugins/toaster';
import { VueQueryPlugin } from '@tanstack/vue-query';
import vue3GoogleLogin from 'vue3-google-login';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faWhatsapp,
  faViber,
  faTelegram,
  faCcVisa,
  faCcMastercard,
  faGooglePay,
  faApplePay,
} from '@fortawesome/free-brands-svg-icons';
import {
  faRug,
  faMugHot,
  faCandyCane,
  faBottleWater,
} from '@fortawesome/free-solid-svg-icons';

import './assets/styles/overrider.scss';
import './assets/styles/fonts.css';
import './assets/styles/transition.scss';
import { plugin, formKitConfig } from '@/plugins/formkit';

import gtm from '@/plugins/gtm'
import { getDomainLocale } from '@/utils/url';
import { gtagOptions, VueGtag } from '@/plugins/gtag';

library.add(
  faWhatsapp,
  faViber,
  faTelegram,
  faRug,
  faMugHot,
  faCandyCane,
  faBottleWater,
  faCcVisa,
  faCcMastercard,
  faGooglePay,
  faApplePay,
);

const app = createApp({
  render: () => h(App),
});

// Предоставление клиентов Apollo
setupApollo(app);

// @ts-ignore
app.use(vue3GoogleLogin, {
  clientId: '899960313134-qgoe1j21fa1bppj57buqom8jnc0ihrnm.apps.googleusercontent.com',
  scope: 'email profile openid',
});
app.use(plugin, formKitConfig);
app.use(VueAppleLogin, {
  clientId: 'com.test.vean',
  scope: 'name email',
  redirectURI: window.location.origin,
  state: new Date().toString(),
  usePopup: true,
});
app.use(installToaster, toasterOptions);
app.use(VueQueryPlugin);

const domainLocale = getDomainLocale('com') ?? 'com';
app.use(VueGtag, gtagOptions, router)
app.use(gtm, {countryCode: domainLocale})
app.directive('mobile', mobile);
app.directive('desktop', desktop);
app.directive('click-outside', ClickOutsideDirective);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(i18n);
app.use(router);
app.use(pinia);
app.use(store);
app.use(ReadMore);
app.use(VueViewer);
app.mount('#app');
