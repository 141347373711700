export default {
  support: {
    problem_situation_link: 'Turiu problemų po seanso',
    other_problem_link: 'Turiu kitą problemą',
    problem_situation: {
      header: 'Problema po seanso',
      form: {
        session: 'Seansas',
        created_by_comment: 'Aprašykite problemą',
      },
    },
    complain: {
      header: 'Skundai ir pasiūlymai',
      form: {
        name: 'Vardas',
        phone_number: 'Telefono numeris',
        email: 'E-Mail',
        parlor: 'Salonas',
        description: 'Aprašykite problemą',
        feedback: {
          overall_quality: 'Kaip vertinate bendrą mūsų salono paslaugų kokybę?',
          professional_skills: 'Ar buvote patenkintas meistro profesiniais įgūdžiais?',
          staff_politeness: 'Kaip mandagūs ir dėmesingi buvo salono darbuotojai?',
          waiting_time: 'Ar buvote patenkintas paslaugos laukimo laiku?',
          staff_professionalism: 'Kaip vertinate mūsų darbuotojų profesionalumą?',
          salon_cleanliness: 'Kaip vertinate salono švarą ir komfortą?',
          service_value: 'Ar manote, kad paslaugų kainos atitinka jų kokybę?',
          recommend: 'Ar rekomenduotumėte mūsų saloną savo draugams ir pažįstamiems?',
        },
      },
    },
  },
  items_view: {
    header: 'Produktai',
    filter_form: {
      name_placeholder: 'Pavadinimas',
      category_placeholder: 'Kategorija',
      parlor_placeholder: 'Salonas',
      all_categories: 'Visos kategorijos',
      all_parlors: 'Visi salonai',
    },
    load_more_button: 'Įkelti daugiau',
    not_found_message: 'Nerasta',
  },
  item_view: {
    error_message: 'Klaida įkeliant duomenis',
    available_in_parlor: 'Galima jūsų salone',
    not_available_in_parlor: 'Negalima jūsų salone',
    similar_items_label: 'Panašūs produktai',
    log_in_to_buy: 'Prisijunkite, kad pirktumėte',
  },
  item_simple_card: {
    studio_price_label: 'Studijos kaina',
    shop_price_label: 'Parduotuvės kaina',
  },
  student_candidate: {
    form: {
      who_studing: 'Studentas arba vietos nuoma',
      phone_number: 'Telefono numeris',
      first_name: 'Vardas',
      last_name: 'Pavardė',
      social_link: 'Nuoroda į bet kurį socialinį tinklą',
      parlors: 'Studija',
      couch: 'Tatuiruočių kėdė',
      find_out: 'Kaip sužinojai apie mus?',
      start_date: 'Pageidaujama pradžios data',
      types: 'Seanso tipai',
      work_permit_document: 'Darbo leidimo dokumentas',
      sanitary_book: 'Sanitarinė knyga',
      identity_proof: 'Asmens tapatybės dokumentas',
      work_photos: 'Darbų nuotraukos',
      end_date: 'Pageidaujama pabaigos data',
      confirmations: 'Patvirtinu, kad',
      know_buy_material: 'Žinau, kad galiu įsigyti medžiagų studijoje',
      customer_will_complete_agreement: 'Kiekvienas mano klientas pasirašys kliento sutartį',
      can_show_documents: 'Galiu pateikti originalius dokumentus studijoje, jei reikia',
      has_country_permission: 'Turiu leidimą gyventi šalyje',
      has_san_book: 'Turiu sanitarinę knygą',
      has_passport: 'Turiu asmens tapatybės dokumentą (ID)',
      conditions: 'Perskaičiau nuomos sąlygas',
    },
  },
  user: {
    referral_links: {
      booking: 'Sesijų rezervavimas',
      student_candidate: 'Studentas ir nuoma',
      store: 'Kliento parduotuvė',
      candidates_form: 'Pakviesti darbuotoją',
    },
  },
  products: {
    want_add: 'Noriu pridėti pasirinktus priežiūros produktus prie seanso kainos',
    no_warranty: '*be šios galimybės negalime garantuoti 100% sėkmingo gijimo',
  },
  booking: {
    fast_booking: 'Greitas užsakymas',
    fast_booking_warning: 'Rezervacija nėra galutinė, mūsų vadybininkas susisieks su jumis, kad patvirtintų sesijos laiką ir datą',
    phone_annotate: 'Kad mūsų pardavimų komanda galėtų greičiau reaguoti, prašome pateikti savo vietinį kontaktinį numerį. Ačiū!',
    increased_risk: 'Dėmesio! Aukštos rizikos sesija',
    increased_risk_extended: 'Pasirinktas seanso tipas ir vieta ant kūno turi padidintą riziką',
    approximate_price: 'Apytikslė kaina',
    price_warning: 'Papildomi daiktai neįtraukti',
    type: {
      info: 'Patogus užsakymo forma su visa reikalinga informacija',
      booking_button: 'REGISTRUOTIS Į SESIJĄ',
      booking_button_promo: 'SU 15% GRĄŽINIMU VEAN MONETOMIS',
      unsure: 'Vis dar neapsisprendėte? Mes jums padėsime!\n',
      consultant_help: 'Mūsų konsultantai mielai jums padės',
      consultation_button: 'KONSULTACIJA',
    },
    color: {
      black: 'Juoda',
      colored: 'Spalvotas',
    },
    controls: {
      next: 'Toliau',
      back: 'Atgal',
      no_select: 'Nepasirinkta',
    },
    place_service: {
      select_studio: 'Pasirinkite studiją',
      service: 'Paslauga',
      size: 'Dydis',
      location: 'Vieta',
    },
    customer_info: {
      login_prompt: 'Norėdami tęsti, prisijunkite prie savo paskyros',
      login_button: 'Prisijungti',
      black_white_colored: 'Juoda-balta ar spalvota?',
      select_style: 'Pasirinkite stilių',
      tell_more: 'Pasidalinkite daugiau apie savo tatuiruočių pageidavimus ir pateikite pavyzdžius (nuorodas)',
      login_promo: 'Prisijungus taikoma 10% nuolaida',
      wish: 'Norai',
    },
    date_time: {
      choose_time: 'Pasirinkite vizito laiką',
    },
    master_card: {
      master_unavailable: 'Meistras šiuo metu nepasiekiamas',
      master_guest_warning: 'DĖMESIO! Meistras yra svečias',
      rank: 'Rangas:',
      works_count: 'Darbų skaičius:',
      book_now: 'Užsisakyk dabar',
      select_another_time: 'Pasirinkti kitą laiką',
    },
    success: {
      session: 'SESIJA',
      become_mystery_shopper: 'Tapti paslaptingu pirkėju',
      add_to_calendar: 'Pridėti įvykį prie kalendoriaus',
      call_on_day: '*salono administratorius jums paskambins sesijos dieną',
      return_to_menu: 'Grįžti į meniu',
      recommended_products: 'Rekomenduojami gaminiai gydymui',
    },
  },
  new_landing: {
    count_block: {
      countries: 'Šalių skaičius',
      studios: 'Studijos',
      awards: 'Apdovanojimai',
      masters: 'Meistrai',
    },
    header_banner: {
      label_1: 'Mes iš musės dramblio nedarome',
      label_2: 'mes darome tatuiruotes!',
      book_now: 'Registruotis dabar',
      consultation: 'Konsultacija',
      prices: 'Kainos',
      top_works: 'Geriausi darbai',
      advantages: 'Privalumai',
      change_parlor: 'Pakeisti saloną',
    },
    course_card: {
      theory_part: 'Teorijos dalis:',
      practice_part: 'Praktikos dalis:',
      advance: 'Papildomi privalumai:',
    },
    helpful_links: {
      for_masters: 'Meistrams',
      tattoo_news: 'Tatuiruočių naujienos',
      tattoo_news_description:
        'Sekite svarbiausias tatuiruočių pasaulio naujienas',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Mūsų meistrai pasirengę dalintis žiniomis, paslaptimis, technikomis',
      rent_space: 'Vietos nuoma',
      rent_space_description:
        'Sužinokite apie bendradarbiavimo galimybes nuomojant darbo vietą VEAN studijose',
      tattoo_promotions: 'Akcijos ir akcijos',
      tattoo_certificates: 'Dovanų kuponai',
      consumables: 'Eksploatacinės medžiagos',
      equipment: 'Įranga',
    },
    price_block: {
      title: 'Kainos',
      loyalty_program: {
        title: 'Lojalumo programa',
      },
      actions: {
        book_now: 'Užsakyti dabar',
        calculate_cost: 'Apskaičiuoti kainą',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Geriausi darbai',
      from_the_world: 'iš viso pasaulio',
    },
    why_vean: {
      title: 'Kodėl VEAN',
      individual_approach: 'Individualus požiūris',
      trust: 'Pasitikėjimas',
      safety_guarantee: 'Saugumo garantija',
      convenient_location: 'Patogi vieta',
      visited_studio: 'Žmonės apsilankė šioje studijoje',
    },
  },
  mystery_shopper: {
    title: 'Paslaptingas pirkėjas',
    description:
      'Paslaptingas pirkėjas yra asmuo, kuris tikrina salonų veiklą, pasirodant kaip paprastas klientas. Jis įvertina aptarnavimo lygį salone.',
    offer:
      'Jei nuspręsite tapti paslaptingu pirkėju šiai sesijai, mes jums padovanosime monetas.',
    instructionTitle: 'Instrukcija',
    instructionDetails:
      'Sesijos metu jums reikės įvertinti saloną ir personalą pagal įvairius kriterijus. Po sesijos gausite SMS su nuoroda į apklausos anketą, kurią turėsite užpildyti sąžiningai.',
    question: 'Ar norite tapti paslaptingu pirkėju?',
    yesButton: 'Taip',
    noButton: 'Ne, ačiū',
    successMessage: 'Jums reikės įvertinti studiją pagal šiuos kriterijus:',
    criteria1: 'Konsultacija telefonu ir socialiniuose tinkluose',
    criteria2: 'Konsultacija studijoje, atvykus',
    criteria3: 'Studijos kainų politika',
    criteria4: 'Išorė: Studijos fasadas, iškabos, vieta ir kt.',
    criteria5: 'Studijos interjeras, aplinka',
    criteria6: 'Prekių, suvenyrų ir gijimo priemonių asortimentas (vitrina)',
    criteria7: 'Atmosfera: Televizija, muzika, kolektyvas',
    criteria8:
      'Užkandžių, pramogų, žurnalų ir katalogų, kurie pateikia eskizus, prieinamumas',
    criteria9: 'Bendravimas su meistru',
    criteria10: 'Bendravimas su administratoriumi',
  },
  cookies: {
    text: `Mes naudojame slapukus, kad pagerintumeime jūsų naršymo patirtį, 
      rodytume individualizuotas reklamas ar turinį 
      ir analizuotume mūsų eismą. Spustelėję „Priimti“, 
      sutinkate su slapukų naudojimu.`,
    confirm: 'Priimti',
    more: 'Daugiau informacijos',
    manage: 'Tvarkyti',
    config: {
      necessary: 'Būtinos',
      preferences: 'Pageidaujamos',
      statistics: 'Statistinės',
      marketing: 'Rinkodaros',
    },
  },
  header_menu: {
    logout: 'Išeitis',
    main: 'Pagrindinis',
    about_us: 'Apie mus',
    discount_page: 'Nuolaidos',
    loyalty_page: 'Lojalumo programa',
    ai_page_link: 'Generuoti eskizą',
    fonts_page_link: 'Tatuiruočių šriftai',
    quality_certificate: 'Kokybės sertifikatai',
    complaints: 'Skundai ir pasiūlymai',
    confidence: 'Privatumo politika',
    public_offer_link: 'Vieša pasiūlymas',
    work: 'Norėčiau dirbti VEAN',
    dockuments_page_link: 'Dokumentai',
    login: 'Prisijungti',
    account: 'Asmeninis kabinetas',
  },
  post: {
    welcome: 'Sveiki! Jūs buvote pas mus seanse',
    no_answer_questions: 'Jūs neatsakėte į šiuos klausimus:',
    point: 'Prašome įvertinti aptarnavimo lygį, o nuo mūsų - dovana!',
    send: 'Siųsti',
    error: 'Tai klaida!',
    not_found: 'Seanso nerasta',
    not_call: 'Nereikia man perskambinti',
    thank: 'Dėkojame už įvertinimą!',
    bot: 'Po trejų savaičių iš naujo numerio Jums paskambins mūsų internetinis botas ir patikslins informaciją dėl paslaugos ir užgijimo eigos.',
    certificate: 'Jums sukurtas akcinis %{gift_price} VeanCoins sertifikatas',
    social_networks:
      'Prašome parašyti atsiliepimą apie mus socialiniuose tinkluose:',
    month:
      'Sertifikatu galite pasinaudoti per mėnesį, būtinai priminkite apie jį administratoriui!',
    dissatisfied:
      'Ar esate nepatenkintas gautomis paslaugomis? Jūsų prašymą išnagrinėsime kuo greičiau.',
    scores: {
      administrator: 'Studijos administratoriaus įvertinimas:',
      master: 'Studijos meistro įvertinimas:',
      recommendations:
        'Man žodžiu ir raštiškai davė rekomendacijas priežiūrai:',
      care: 'Мan pasiūlė profesionalią priežiūrą:',
      sketch: 'Мan pasiūlė užsakyti suasmenintą eskizą/proektą:',
      souvenir: 'Мan pasiūlė įsigyti suvenyrus:',
      master_contact:
        'Ar meistras jums paliko savo asmeninius kontaktinius duomenis (asmeninį telefono numerį, nuorodą į asmeninę socialinę žiniasklaidą), ar jūs meistrui palikote savo?',
      repeat_record: 'Norite pakartoti įrašą?',
    },
    scores_mystery_shopper: {
      instagram: 'Mūsų INSTAGRAMO įvertinimas',
      site: 'vean-tattoo svetainės įvertinimas.%{locale}',
      phone_consultation: 'Коnsultacija telefonu ir socialiniuose tinkluose',
      consultation: ' Asmeninė kоnsultacija atėjus į studiją',
      price: 'Jūsų nuomonė apie studijos kainų politiką',
      exterior: 'Išorė: studijos fasadas, iškabos, lokacija ir t.t.',
      interior: 'Studijos interjeras, aplinka',
      assortment: 'Suvenyrų ir užgydymo priemonių asortimentas (vitrina)',
      atmosphere: 'Vidinė atmosfera: televizija, muzika, kolektyvas',
      leisure:
        'Ar yra skanėstų, laukimo laiko organizavimas, žurnalai ir eskizų katalogai',
      master: ' Bendravimas su meistru',
      admin: 'Bendravimas su administratoriumi',
      master_one_place: 'Ar meistras dirba tik šioje studijoje?(taip/ne)',
      master_contact:
        'Meistras, paprašius, davė asmeninius kontaktus? (taip/ne)',
      care: 'Ar Jums pasiūlė nusipirkti užgydymo priemones ir mūsų suvenyrinę produkciją, padovanojo magnetuką ar užbaigto darbo 10% vertės nuolaidą sekančiai paslaugai? (taip/ne)',
      conclusion: 'Apibendrinantis įvertinimas',
    },
    inputs: {
      cost: 'Aš užmokėjau už darbą:',
      cost_other: 'Таip pat nupirkau papuošalus /priežiūros priemones už eurų:',
      review: 'Pastebėti mūsų darbo trūkumai:',
      photo: 'Фото салона',
    },
    inputs_mystery_shopper: {
      recommendation: 'Jūsų patarimai mums',
      why_vean: 'Kodėl jūs pasirinkote VEAN?',
    },
  },
  header: {
    online_appointment: 'Užsirašyti vizitui tinklalapyje',
    post_session: 'Kokybės įvertinimas',
    get_price: 'Konsultacija',
    consultation: '(Konsultacija)',
    order_consultation: 'Užsisakykite konsultaciją',
  },
  index: {
    parlor: 'Studija',
    master: 'Meistras',
    session_type: 'ТPaslaugos tipas',
    session_location: 'Paslaugos suteikimo vieta',
    datetime: 'Rinktis datą ir laiką',
    create_appointment: 'Užsirašyti',
    bonus:
      '* DOVANOJAME %{customer_bonus} VeanCoins UŽ SAVARANKIŠKĄ UŽSIRAŠYMĄ',
    sketch: 'Eskizas (nebūtinai)',
  },
  session_types: {
    tattoo: 'Tatuiravimas',
    tatouage: 'Permanentinis (ilgalaikis) makiažas',
    piercing: 'Auskarų vėrimas',
    stroke: 'Šalinimas',
    training_tattoo: 'Tatuiravimo mokymas',
    training_piercing: 'Auskarų vėrimo mokymas',
    training_tattouage: 'Permanentinio (ilgalaikio) makiažo mokymas',
    training_udder: 'Šalinimo mokymas',
  },
  price_form: {
    client_name: 'Vardas',
    client_phone: 'Telefono numeris',
    client_comm: 'Paslauga',
    sketch_desc: 'Pridėti eskizą (nebūtinai)',
    sketch_button: 'Pasirinkti failą',
    send: 'Siųsti',
    or: 'Arba',
    country: 'Šalis',
    city: 'Miestas',
    comment: 'Komentaras',
    val_error: 'Patikrinkite įvestus duomenis!',
    ths_message: 'Dėkojame!  Artimiausiu laiku susisieksime su Jumis',
    go_back: 'Grįžti',
    file_size: 'Failo dydis neturi viršyti 5 Mb!',
  },
  selectors: {
    parlor: {
      placeholder: 'Paieška pagal salono pavadinimą...',
    },
    master: {
      placeholder: 'Paieška pagal meistro vardą, paslaugą…',
    },
    select_type: {
      select: 'Pasirinkti',
      next: 'Toliau',
      no_price: 'Sutartinė',
      ad: 'nuo',
      da: 'iki',
      selected: 'selected',
      info: 'Kaina gali priklausyti nuo dydžio ir vietos',
    },
    datetime: {
      select_date: 'Pasirinkite pageidaujamus datą ir laiką',
      no_active_times:
        'Šiems datai/laikui jau nebėra laisvų meistrų. Prašome pasirinkti kitą datą/laiką!',
    },
  },
  verify: {
    verify_account: 'Patvirtinti paskyrą',
    type: 'Patvirtinimo būdas',
    check_code: 'Patikrinkite kodą',
    status: 'Statusas',
    input_phone: 'Įveskite telefono numerį',
    email_help: 'Jūs taip pat galite naudoti el. paštą verifikacijai',
    sms_sent: 'Išsiuntėme Jums SMS-kodą, įveskite jį langelyje žemiau',
    resend_sms_after: 'Pakartotinai galite siųsti po ..... sekundžių',
    resend_sms: 'Siųsti dar kartą',
    buttons: {
      back: 'Grįžti',
      next: 'Tęsti',
      create_appointment: 'Užsirašyti',
    },
  },
  success: {
    h1: 'Dekojame! Registravimo forma sėkmingai sukurta!',
    h2: 'Administratorius atsiūs Jums SMS-patvirtinimą',
    mystery: 'Tapkite slaptu pirkėju',
  },
  about: {
    header: 'Informacija apie jus',
    birth_info:
      'Jūsų gimimo diena patvirtina leistiną amžių ir įgalina suteikti Jums nuolaidą Gimimo Dienos proga :)',
    inputs: {
      name: 'Vardas ir pavardė',
      find_out: 'Iš kur sužinojote apie mus',
      birth_date: 'Gimimo data',
    },
    buttons: {
      skip: 'Praleisti',
      back: 'Grįžti',
      next: 'Tęsti',
    },
  },
  footer: {
    header: 'Stebėk mus soc. tinkluose, kad pirmam sužinoti naujienas',
    site_navigation: 'Navigacija',
    interest: 'Įdomu',
    other: 'Kita',
  },
  subscribe_modal: {
    header: 'Prenumeruokite naujienas už premiją',
    submit: 'Siųsti',
    wrong_data: 'Patikrinkite įvestų duomenų teisingumą!',
    wrong_email: 'Neteisingai įvestas el. pašto adresas!',
    thanks: 'Ačiū už prenumeratą!',
    error: 'Įvyko klaida!',
  },
  chat_bot: {
    call: 'Paskambinti',
    online_record: 'Registracija internetu',
    online_consultation: 'Konsultacija internetu',
    need_help: 'Reikia pagalbos su eskizu?',
    order_sketch: 'Užsisakyti eskizą',
    dress_sketch: 'Pasimatuoti eskizą',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problematinės situacijos',
      id: 'Numeris',
      status: 'Būsena',
    },
    reset_password: {
      title: 'Pamiršote slaptažodį',
      submit: 'siųsti',
      forgot_password: 'Pamiršote slaptažodį',
      phone: 'Įveskite telefono numerį',
      success_message: 'Laikinas slaptažodis buvo išsiųstas į jūsų el. paštą/telefoną',
    },
    registration: {
      name: 'Vardas',
      surname: 'Pavardė',
      registered: 'Registracija',
      congratulations: 'Sveikiname!',
      successfully_registered: 'Jūs sėkmingai užsiregistravote!',
      log_in: 'Prisijungti',
    },
    options: {
      no_interest_in_promotions: 'Nemanau reklamos ir premijų',
      dislike_frequent_sms: 'Nemėgstu dažnų SMS pranešimų',
      already_have_tattoo:
        'Aš jau turiu tatuiruotę ir artimiausiu metu neplanuoju daryti kitos',
      other: 'Kitas',
    },
    login_page: {
      log_in_page: 'Prisijungimas prie asmeninės paskyros',
      number_missing: 'Neturite numerio?',
      register: 'Registruokitės!',
      steps: {
        sign_up: 'Užsiregistruokite lojalumo programoje arba PRISIJUNKITE',
        check_coins: 'Patikrinkite savo VeanCoin užsiregistruodami lojalumo programoje',
        get_coins: 'Gaukite savo VeanCoin kiekvienos sesijos pabaigoje',
        accumulate: 'Rinkite ir kaupti VeanCoin savo profilyje',
        book_session: 'Užsisakykite sesiją ir ateikite į Vean, kad gautumėte savo tatuiruotę',
        exchange_coins: 'Keiskite VeanCoin ir gaukite nuolaidas',
      },
    },
    find_customer: {
      continue: 'Tęsti',
      not_found: 'Klientas nerastas!',
      select_type: 'Pasirinkite prisijungimo tipą',
      no_password: 'Nenustatėte slaptažodžio!',
      found: 'Rastas!',
      check: 'Tikrinama...',
      invalid_phone_or_password:
        'Neteisingas telefono numeris arba slaptažodis',
      error_login: 'Klaida prisijungimo metu',
      phone: 'Telefonas',
    },
    product_card: {
      availability: 'Prieinamumas studijose:',
      unavailable: 'Šiuo metu nėra',
    },
    store_body: {
      full_points_products: 'Prekės, kurias galima įsigyti su VeanCoins',
      half_points_products: 'Prekės, kurias galima įsigyti su 50 % nuolaida',
      no_products: 'Prekių nėra',
    },
    description_block: {
      loyalty_system: 'Lojalumo sistema VEAN',
      show_more: 'Parodyti daugiau',
    },
    profile_card: {
      my_sketches: 'Manieji eskizai',
      share_link: 'Pasidalinkite šia nuoroda',
      tips: 'Arbatpinigiai',
      send_certificate: 'Siųsti sertifikatą',
      vip: 'VIP',
      not_vip: 'Tapti VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Pinigai',
      active_certificates: 'Aktyvuotos monėtos',
      balance: 'Balansas',
      sessions: 'Sesijos',
      verify_account_status: 'Paskyros būsena',
      security_percent: 'Saugumas:',
      points_alltime: 'Visą laiką uždirbtos monėtos',
      available_certificates: 'Prieinami sertifikatai',
      certificates: 'Pinigų sertifikatai',
      bought_certificates: 'Aktyvuotos VeanCoins',
      certificate_number: 'Sertifikato numeris',
      available_for: 'Galioja iki:',
      no_active_certificates: 'Nėra piniginių sertifikatų',
      no_active_bought_certificates: 'Nėra aktyvių VeanCoins',
      coming_sessions: 'Būsimos sesijos',
      complete_agreement: 'Užpildyti sutartį',
      date: 'Data:',
      you_was: 'Jūs dalyvavote %{sessions} sesijose',
      edit: 'Redagavimas',
      not_email: 'norėdami išsiųsti sertifikatą, turite užpildyti el. paštą',
      exist_application: 'yra programa',
      need_exist_application: 'nėra programos',
      need_confirm_account: 'Reikalingas sąskaitos patvirtinimas',
      verified: 'Patvirtinta',
      choose_payment_method: 'Pasirinkite mokėjimo būdą',
      parlor: 'Salonas',
      sign_up_online: 'Užsiregistruokite sesijai',
      mystery_shopper: 'Paslaptingas pirkėjas',
      sign_up_secret_buyer:
        'Užsiregistruokite pas mus į sesiją ir tapti paslaptingu pirkėju',
      pay: 'Mokėti',
      prepayment: 'Išankstinis mokėjimas',
      questionnaire: 'Klausimynas',
      agreement: 'Sutartis',
      fill_required_information: 'Būtina užpildyti informaciją',
      buy: 'Papildyti balansą',
      archive: 'Archyvas',
      support_service: 'Vean Tattoo Community klientų aptarnavimas',
      balance_buy_history: 'Pirkimo taškų VeanCoins',
      balance_history: 'Gautų taškų VeanCoins',
      buy_history: 'Pirkimų istorija',
      сost: 'Kaina',
      payment_link: 'Mokėjimo nuoroda',
      attributes: 'Atributai',
      invite_friend: 'Pakviesti draugą',
      phone_or_email: 'Įveskite telefono numerį arba el. pašto adresą',
      report_problem: 'Pranešti apie problemą',
      note: 'Įveskite pastabą',
      tooltip_coins:
        'jums reikia aktyvuoti savo sukauptas monėtas pasirinktai paslaugai ar prekių pirkimui, kad išvengtumėte jų praradimo per 1 metus',
      tooltip_certificate:
        'jūsų įsigytų arba gautų dovanų pinigų sertifikatų skaičius, kurie gali būti panaudoti per 2 mėnesius',
      tooltip_сonfirmed:
        'Redagavimo metu pakeiskite «ФИО или username» į «Vardas ir Pavardė»',
      tooltip_missing:
        'Pridėkite informaciją apie save (vardą ir pavardę, gimimo datą, nuorodą į jūsų socialinius tinklus, iš kur sužinojote apie mus, telefono numerį ir el. paštą tapatybės patvirtinimui)',
      tooltip_verified:
        'Jums reikia patvirtinti savo paskyrą naudojant telefono numerį arba el. paštą, kad apsaugotumėte įgytas ir sukauptas VeanCoins.',
      tooltip_vip:
        'VIP klientas yra statusas, kurį gali gauti kiekvienas klientas, išleidęs paslaugoms daugiau nei 1500 eurų. VIP statusas suteikia tam tikras privilegijas jo savininkui, pvz., greitą pirmenybinį registravimą į sesijas be eilės, prieigą prie unikalių prekių ir paslaugų, pasiekus VIP statusą, klientas iš karto gauna 1000 monėtų įskaičiuotas į savo sąskaitą',
    },
    profile_edit: {
      additional_information: 'Papildoma informacija apie VeanCoins',
      parlors: 'Salonas (kuris yra arčiau arba pagrindinis)',
      client_card: 'Kliento kortelė',
      full_name: 'Vardas ir pavardė arba vartotojo vardas',
      birth_date: 'Gimimo data (sveikinimui)',
      social_media_link: 'Nuoroda į socialinę mediją',
      find_out: 'Kaip sužinojote',
      or: 'arba',
      phone_placeholder: 'Telefonas',
      save: 'Išsaugoti',
      password_tooltip:
        'Sukurkite slaptažodį, kad ateityje galėtumėte prieiti prie savo profilio be SMS ir el. laiškų pagalbos. Tai taip pat apsaugo jūsų asmeninius duomenis.',
      password: 'Slaptažodis',
      repeat_password: 'Pakartokite slaptažodį',
      email_taken: 'Šis el. pašto adresas jau užimtas!',
      phone_taken: 'Šis telefono numeris jau užimtas!',
      simple_password: 'Per paprastas slaptažodis!',
      invalid_password: 'Neteisingas slaptažodis!',
      password_for_application: 'Slaptažodis programai (neprivaloma)',
      congratulate: 'Sveikiname!',
      successfully_registered: 'Sėkmingai užregistruotas!',
      need_confirm_account: 'Reikia patvirtinti paskyrą',
      yes: 'Taip',
      no: 'Ne',
      phone_confirmed: 'Telefonas patvirtintas',
      email_confirmed: 'El. paštas patvirtintas',
      change_password: 'Keisti slaptažodį',
      old_password: 'Senas slaptažodis',
      new_password: 'Naujas slaptažodis',
      confirm_password: 'Pakartokite slaptažodį',
      invalid_phone_or_password: 'Neteisingas telefono numeris ar slaptažodis',
      error_login: 'Klaida prisijungimo metu',
      years_old: 'Amžius',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Lytis',
      man: 'Vyras',
      woman: 'Moteris',
      gender_hidden: 'Pageidauju nenurodyti',
      language: 'Kalba',
      preferred_style_status: 'Pageidaujami stiliai',
      classic: 'Klasika / Realizmas',
      freak: 'Freak / Trash / Anime',
      fashion: 'Mada / Menas / Minimalizmas',
      rock: 'Rokas / Hard',
      pop: 'Pop / Masės',
      accommodation_status: 'Gyvenamoji vieta',
      local: 'Vietinis',
      tourist: 'Turistas',
      guest: 'Svečias',
      lifestyle_status: 'Gyvenimo būdas',
      extreme: 'Ekstremalus',
      dance: 'Šokis',
      anime: 'Anime',
      game: 'Žaidimas',
      music: 'Muzika',
      lgbt: 'LGBT',
      art: 'Menas / Modernumas',
      family: 'Šeima / Meilė',
      beauty: 'Grozis',
      sexuality: 'Seksualumas',
      masculinity: 'Vyriškumas',
      tattoo_count_status: 'Tatuiruočių skaičius',
      pierce_count_status: 'Prasiskverbusių skaičius',
      one: 'Vienas',
      two: 'Du arba daugiau',
      inc: 'Labai daug',
      change_information: 'Išsaugoti informaciją',
    },
    verification: {
      sms: 'Patvirtinti per SMS',
      email: 'Patvirtinti per Email',
      google: 'Patikrinti per Google',
      send_code: 'Siųsti kodą',
      code: 'Patvirtinimo kodas',
    },
    loyalty: {
      submit_button: 'Siųsti',
      bonus_instruction:
        'Atlikite vieną iš išvardytų veiksmų, pridėkite įrodymų (ekranvaizdžių, nuotraukų ir pan.) ir gaukite premiją',
      bonus_amount: 'VeanCoins!',
      review_option_1:
        'Palikite išsamų atsiliepimą meistro profilyje svetainėje',
      review_option_2: 'Įvertinkite studiją ar meistrą svetainėje',
      review_option_3: 'Įvertinkite studiją ir palikite atsiliepimą žemėlapyje',
      review_option_4: 'Pasidalinkite mūsų studija socialiniuose tinkluose',
      return:
        'Jeigu jūsų pateiktas nuotraukas neįtraukiame į jokias kategorijas, kurios teikia premijas, gautos premijos bus atšauktos perkant dovanų sertifikatą.',
      evidence_label: 'Įrodymai',
    },
    buy_certificate: {
      payment_type_title: 'Pirkimo tipas',
      payment_type_subtitle: 'Ką norite padaryti',
      buy_balance: 'Įkrauti balansą',
      buy_certificate: 'Pirkti sertifikatą',
      amount_title: 'Suma',
      amount_subtitle: 'Įveskite sumą',
      payment_method_title: 'Mokėjimo būdas',
      payment_method_subtitle: 'Pasirinkite mokėjimo būdą',
      payment_title: 'Mokėjimas',
      forming_payment: 'Mokėjimo formavimas...',
      pay: 'Mokėti',
      continue: 'Tęsti',
      back: 'Atgal',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Daugiau nei 150 salonų',
      clients_count: 'Daugiau nei 1 000 000 klientų',
      largest_company: 'Didžiausias pasaulyje tatuiruočių studijų tinklas',
    },
    master_block: {
      our_masters: 'Mūsų meistrai',
    },
    master_card: {
      works_count: 'Darbų skaičius: ',
    },
    no_admin_block: {
      leisure: 'Laikas laisvalaikiui',
      session_record: 'Užsiregistruoti seansui',
      about_company: 'Apie įmonę',
      care: 'Priežiūra',
      quality_certificates: 'Kokybės sertifikatai',
      public_buyout_offer: 'Viešas pasiūlymas',
      complaints_offers: 'Skundai ir pasiūlymai',
      loyalty_system: 'Sistema lankstumui',
      discount_page: 'Nuolaidos',
    },
    peculiarities_block: {
      about_studio: 'Apie šią studiją',
      clients: 'Klientai',
      sessions: 'Seansai',
      studio_hours: 'Darbo valandos: ',
      studio_phone: 'Studijos numeris: ',
      wifi: 'Wi-fi: ',
      password: 'Slaptažodis: ',
      from_to: 'nuo %{time_start} iki %{time_end}',
    },
    photos_block: {
      photos: 'Salono nuotrauka',
    },
    top_works: 'Geriausių darbų galerija',
    care_important:
      'Svarbiausia auskarų vėrime ir tatuiravime – tai užgydymas bei priežiūra.',
    cabinet: 'Asmeninis kabinetas',
    complete_agreemnent: 'Užpildykite sutartį',
    is_blanket: 'Yra antklodė, su kuria galite pasišildyti',
    is_water: 'Yra karšti gėrimai',
    is_hot_drinks: 'Yra saldainiai',
    is_treats: 'Yra šalti gėrimai',
  },
  promotion: {
    submit: 'Siųsti',
    warning: 'Dėmesio! Patikrinkite įvestų duomenų teisingumą!',
    name: 'Vardas / Slapyvardis',
    required: 'Šis laukas privalomas!',
    max_value: 'Neturėtų būti daugiau nei 100 simbolių!',
    phone: 'Telefonas',
    social_link: 'Nuoroda į socialinį tinklą',
    wrong_format: 'Neteisingas formatas!',
    terms: 'Sutinku su asmens duomenų tvarkymo taisyklėmis',
    know_bonus: 'Žinau, kad man bus suteikti premijos taškai',
    error: 'Įvyko klaida',
    thanks: 'Ačiū!',
    bonus: 'Jums suteikta %{gift_price} taškų',
    do_not_disturb:
      'Nesijaudinkite (išjungti SMS ir skambučius nuo VeanTattoo)',
    get_promo: 'Norėčiau gauti informaciją apie nuolaidas ir akcijas',
    first_time_veancoins_notification:
      'Pirmą kartą naudojant VeanCoins, reikia susisiekti su studijos administratoriumi. VeanCoins ir Pinigai turi skirtingus naudojimo taisykles',
  },
  another_studio_drawler: {
    another: 'Kitos studijos',
  },
  landing: {
    best_studio: 'Geriausia tavojo miesto tatuiravimo studija',
    vean_wide: 'VEAN TATTOO TINKLAS',
    studio_count: 'Virš 150 tatuiravimo studijų',
    quality: 'Kokybės ir saugumo garantija',
    get_price: 'SUŽINOTI KAINĄ',
    contact_us: 'Susisiekite su mumis',
    advantage: 'Privalumai',
    top_works: 'Geriausių darbų galerija',
    prices: 'Kainos', //долджен быиь
    studio_photo: 'Studijos nuotraukos',
    prices_services: 'Kainos ir paslaugos',
    about: 'Apie mus',
    loyalty_system: 'Lojalumo programa', //privaloma
    why_vean: 'Kodėl VEAN',
    visit_count: 'Žmonių aplankė šią studiją',
    description:
      'VeAn Tattoo studijų tinklas šiuo metu didžiausias pasaulyje: virš 150 salonų 14 Europos šalių. Мes aukštai pakėlėme šios srities standartų lygį. Tinklo specializacija pakankamai plati, bet pagridinė kryptis tai tatuiravimo ir auskarų vėrimo industrija. Pagrindiniai kompanijos VeAn Tattoo principai: Patirtis ir prieinamumas: Мes siekiame, kad kiekvienas klientas patogiai jaustųsi kiekviename proceso etape, todėl mūsų specialistai pasiruošę atsakyti į visus jūsų klausimus internetu. Reputacija ir dėmesys klientui: Кiekvienas klientas – tai galimybė sukurti ant jo odos unikalų meno kūrinį. Мes tausojame jūsų laiką ir siekiame atleisti jus patenkintus nuostabia tatuiruote. Мums rūpi reputacija, vertiname kiekvieną tinklo klientą. Vertė ir prieinamumas: Мes siūlome puikų kainos ir kokybės santykį. Mūsų paslaugos prieinamos plačiajai auditorijai, bet, mes neaukojame kokybės. Sertifikuota ir kokybiška įranga: Mūsų medžiagos atitinka aukščiausius saugumo ir kokybės standartus. Мes garantuojame, mūsų klientai gauna tik tai, kas geriausia. Aukštos kvalifikacijos specialistai ir platus paslaugų spektras: VeAn Tattoo  studijose dirba tik diplomuoti meistrai, kuriuos vertina ir gerbia visame pasaulyje. Мes siūlome visus esamus tatuiravimo stilius ir technikas, parengti nemokamą asmeninį eskizą pagal jūsų pageidavimus. Taip pat raginame užsiprenumeruoti visus mūsų oficialius puslapius socialiniuose tinkluose. Taip galėsite gauti naujausią informaciją apie kompanijos VeAn Tattoo naujoves ir specialius pasiūlymus, akcijas, konkursus, meistrų darbus',
    advantages: {},
    our_masters: 'Mūsų meistrai', //privaloma
    from: 'nuo',
    no_price: 'Sutartinė',
    form: {
      get_price_consultation: 'Sužinoti kainą / Коnsultacija',
      name: 'Vardas',
      phone: 'Telefono numeris',
      place: 'Jūsų miestas',
      subscribe_news: 'Užsiprenumeruokite mūsų naujienlaiškį ir gaukite dovanų',
    },
  },
  form_messages: {
    submit: 'SIŲSTI',
    warning: 'Dėmesio! Patikrinkite įvestų duomenų teisingumą!',
    required: 'Tai būtinas užpildyti laukelis!',
    length: 'Neviršyti %{length} raidžių!',
    file_length: 'Neviršyti %{length} failų!',
    wrong_format: 'Netinkamas formatas!',
    error: 'Įvyko klaida',
    thanks: 'Dėkoju!',
    required_label: 'Privalomas',
    not_required_label: 'Neprivalomas',
  },
  training: {
    training: 'MOKYMAI',
    tattoo_school: 'TATUIRŲOTŲ MOKYKLA',
    biggest_school: 'didžiausias tatuiruočių salonų tinklas pasaulyje',
    ne_otkladivaj: 'Nedėkite<br/>savo gyvenimo į šalį vėlesniam laikui',
    learn:
      'Išmokite daryti tatuiruotes<br/>ir tapkite aukščiausios klasės meistru',
    reviews: 'Absolventų atsiliepimai',
    record: 'Užsiregistruoti',
    video_text: 'Kaip jūs galite padėti,<br/>ko išmokysite?',
    ded: {
      consultation: 'konsultacija',
      get_more: 'Sužinokite daugiau informacijos',
    },
    header_bar: {
      main: 'Pagrindinis',
      get: 'Ką gausite',
      program: 'Kurso programa',
      about: 'Apie mus',
      reviews: 'Atsiliepimai',
    },
    our_course: {
      head: 'Mūsų<br/>kursai',
      description_1:
        'tai vieta, kur visada gausite atsakymus į savo klausimus ir išmoksite kažką naujo',
      description_2:
        'Net jei neturite meninio išsilavinimo - rezultatas neprivers jūsų ilgai laukti',
      label: '[ pas mus šilta, jauku ir įdomu ]',
      description_3:
        'Mes mylime savo studentus ir stengiamės viską padaryti, kad mokymosi procesas būtų kuo patogesnis',
    },
    banner: {
      we_teach: 'Mes išmokysime Jus daryti tatuiruotes',
      good_make: 'MES DARBOME TATUIRUOTES PUPIAI',
      our_masters:
        'MŪSŲ MEISTRAI PASIRUOŠĘ DALINTIS ŽINIOMIS, PASLAPTĮMIS, TECHNIKOMIS',
      learn_too: 'Ir mes išmokysime ir jus',
    },
    modal: {
      thanks: 'Ačiū!',
      error: 'Įvyko klaida!',
      complete: 'Užpildykite formą ir mes su jumis susisieksime',
      full_name: 'Pilnas vardas ir pavardė',
      phone: 'Telefono numeris',
      submit: 'Siųsti',
      cancel: 'Atšaukti',
    },
    paragraph: {
      header_1: 'Tikrai išmokysime',
      content_1: 'Išmokysime visų tatuiravimo pagrindų',
      header_2: 'mokėti piešti',
      content_2:
        'Mes Jus visko išmokysime, nebūtina mokėti piešti ar turėti meninį išsilavinimą',
      header_3: 'Sertifikatas',
      content_3:
        'Baigęs kursus gausite tarptautinio pavyzdžio VEAN diplomą, patvirtinta Europinės Meistrų Asociacijos',
      header_4: 'Įdarbinimas',
      content_4:
        'Ar mes rimtai? Taip, geriausi absolventai gali tapti mūsų komandos dalimi ir dirbti bet kurioje iš mūsų studijų Lietuvoje ir užsienyje',
      header_5: 'Sutartis',
      content_5: 'Pasirašome oficialią sutartį',
      no_sleep: {
        header: 'ne praleiskite',
        rect_1: 'Kaip?',
        rect_2: 'GYVENIMAS',
        main_label: 'Pradėkite mokytis dabar',
        label_1: '1000+ absolventų',
        label_2: '100% užbaigė mokymus',
        label_3: '80% įsidarbino',
        label_4: '300+ partnerių tatuiruočių industrijoje',
      },
    },
    principies: {
      head: 'Garantijos',
      label_1: '1. Kultūra',
      label_2: '2. Kokybė',
      label_3: '3. Metodai',
      heading: 'Viskas grindžiama principais',
      description_1:
        'Prieš 11 metų nusprendėme plėtoti tatuiruočių industrijos kultūrą, ne kaip visi, savo būdu - remdamiesi žiniomis ir patirtimi',
      description_2:
        'Šiai dienai apmokėme virš 1000 puikių tatuiravimo meistrų. 80% tapo mūsų komandos dalimi. Galime drąsiai teigti, kad tatuiravimo meno gali išmokti kiekvienas',
      description_3:
        'Viskas priklauso nuo naudojamos metodikos, kurią nuolat tobuliname kartu su komanda, menininkais ir mūsų absolventais, kurie reguliariai teikia atsiliepimus',
    },
    packages: {
      head: 'Kursų programos',
      from: 'nuo',
      plus: 'Privalumai',
    },
    facilities: {
      head: 'Galimybės ir privalumai',
      subhead:
        'Pasirinkite mūsų kursus ir pajuskite tatuiruočių industrijos magiją',
      card: {
        head_1:
          'Kiekvienas mūsų absolventas gauna tarptautinio standarto sertifikatą',
        label_1: '- jūsų bilietas į galimybių pasaulį',
        head_2: 'Rekomendacijos ir palaikymas iš profesionalių meistrų grupės',
        label_2: 'Jūsų sėkmė yra mūsų pasididžiavimas!',
        head_3: 'Naujas etapas tatuiruočių pasaulyje prasideda jau dabar',
        label_3: 'Jūsų naujas gyvenimas tatuiruočių pasaulyje prasideda dabar!',
      },
    },
    review: {
      head_1: 'Mūsų studentų darbai',
      head_2: 'Atsiliepimai',
      comment_1:
        'Tatuiruočių mokymai šioje mokykloje viršijo visus mano lūkesčius! Mokytojai yra savo srities profesionalai, kiekvienas mokymo etapas yra išsamiai aptariamas, atmosfera yra nuostabi. Dabar galiu su pasitikėjimu įgyvendinti savo kūrybines idėjas ant klientų odos!',
      comment_2:
        'Dėkoju mokyklai už tokią kokybišką tatuiruočių mokymą! Pradžioje buvo šiek tiek baisu imtis šio reikalo, bet dėka dėmesingų ir kantrių mokytojų jaučiausi užtikrintai. Dabar mano darbai sulaukia pripažinimo ir žavėjimosi!',
      comment_3:
        'Tai buvo neįtikėtina kelionė į tatuiruočių pasaulį! Mokykla suteikia visas būtinas žinias ir įgūdžius, kad taptumėte tikru profesionalu. Esu dėkingas už palaikymą ir įkvėpimą, kurį čia gavau',
    },
  },
  questionary: {
    first: {
      heading: 'SUTIKIMAS DĖL PROCEDŪROS VEA N TATTOO STUDIOJE',
      textbox:
        'Jei klientas yra jaunesnis nei 18 metų, sutikimą turi užpildyti tėvai arba teisiniai globėjai (Britų Kolumbijoje, Kanadoje, nepilnametis laikomas asmeniu, kuriam nėra 19 metų. Jei jums yra mažiau nei 19 metų, prašome pasirinkti ANTRĄJĄ parinktį)',
      has_18: 'TAIP, man yra 18 metų arba vyresni',
      no_18: 'NE, man yra mažiau nei 18 metų',
    },
    second: {
      warning: 'Dėmesio! Sesijos dieną būtina atvykti su priežiūros asmenimis!',
      name: 'Pilnas vardas',
      birthdate: 'Gimimo data',
      address: 'Gyvenamoji adresas',
      email: 'El. paštas',
      sex: 'Lytis',
      heading: 'ATLIEKAMAS PROCEDŪRAS',
      man: 'Vyras',
      woman: 'Moteris',
      other: 'Nenurodyta',
      parent_data: 'Vieno iš tėvų duomenys:',
      child_data: 'Vieno NEPILNAMEČIO duomenys:',
      child_field_placeholder: 'Sūnus, dukra ir kt.',
      child_field_label: 'Kam atliekama procedūra',
      confirm:
        'Aš, būdamas nepilnamečio teisėtas atstovas, sutinku, kad mano vaikas atliktų tolesnį procesą.',
    },
    third: {
      heading: 'VISKAS, KĄ TURITE ŽINOTI APIE GYDYMO LAIKOTARPĮ:',
    },
    fourth: {
      nothing: 'Nė vienas iš aukščiau išvardytų',
      heading1:
        'JEI TURITE KOKIŲ nors ŽEMESNIŲ LIGŲ, MES TURIME ŽINOTI, PRAŠOME PAŽYMĖKITE ATITINKAMUS TAŠKUS:',
      heading2:
        'JEI YRA KONTRAINDIKACIJŲ, PRAŠOME PATEIKTI ATITINKAMĄ INFORMACIJĄ:',
    },
    fifth: {
      heading: 'UŽDRAUSTA PO PROCEDŪROS:',
    },
    sixth: {
      heading: 'KUR APRAŠYTA APIE MUS:',
      label:
        'Po 21 dienos vienas iš mūsų darbuotojų gali su jumis susisiekti kaip dalis paslaugų kokybės kontrolės.',
    },
    seventh: {
      heading: 'PAREIŠKIMAS IR SUTIKIMAS',
    },
    signature: {
      heading:
        'Aš, pasirašęs (%{name}), šiuo metu sutinku su šios dienos procedūra su dalyvavimu\n' +
        '%{session_type} mano kūne ir sutinku su šio dokumento sąlygomis.',
    },
    next: 'Toliau',
    submit: 'Pateikti',
    no_responsibility: 'Studija neatsako už šią sesiją',
    acquaint: 'Susipažinau su man suteikta informacija',
    acquaint_2: 'Suprantu, kad Vean Tattoo nėra atsakingas už gijimo procesą.\n' +
      'Aukščiau paminėto tatuiruotės ar auskarų korekcijos atveju korekcijos kaina sudarys 30% pačios paslaugos kainos.\n' +
      'Jei nesate patenkinti korekcijos rezultatu, šiuo atveju lėšos už paslaugą negrąžinamos, nes minėtos sritys nėra palankios pačiai paslaugai atlikti.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Nėra sprendimo',
      in_check: 'Tikrinama',
      problem_solved: 'Problema išspręsta',
      wait: 'Laukiama',
      small: 'Nedidelė situacija',
      unanswered_check: 'Tikrinimas be atsakymo',
    },
  },
  master_page: {
    card: {
      active: 'Aktyvus',
      not_active: 'Neaktyvus',
      master_since: 'Meistras VEAN nuo',
      services: 'Paslaugos',
      styles: 'Stiliai',
      book: 'Užsakyti',
    },
    information: {
      top_works: 'Geriausi darbai',
      awards_and_diplomas: 'Apdovanojimai ir diplomai',
    },
  },
  stripe_warning: 'Dėmesio! Minimali stripe mokėjimo suma yra 20 €. Jei reikia mažesnės sumos, kreipkitės į studiją dėl informacijos',
  check_in: {
    welcome_message: 'LINKIME MALONIOS SESIJOS!',
    session_started: 'JŪSŲ SESIJA PRASIDĖJO',
    next_steps: 'Baigę sesiją, jums bus prieinami kiti veiksmai',
    buy_healing_product: 'REKOMENDUOJAME PIRKTI GYDOMĄJĮ PRODUKTĄ',
    skip: 'Praleisti',
    leave_review: 'Palikite atsiliepimą',
    your_impression: 'Kokie jūsų bendri įspūdžiai?',
    add_photo: 'Pridėkite darbo nuotrauką, kad gautumėte papildomą 1 Vean Coin',
    send: 'Siųsti',
    payment_success: 'Mokėjimas sėkmingas',
    get_invoice: 'Ar norite gauti sąskaitą?',
    invite_friend: 'Pakviesti draugą',
    recommend_us: 'Rekomenduokite mus savo draugams ir uždirbkite kartu su jais',
    thank_employees: 'Taip pat rekomenduojame padėkoti mūsų darbuotojams',
    you_can_tip: 'Brangus Nikita, galite padėkoti mūsų darbuotojams tam tikru arbatpinigiu',
    own_amount: 'Savo suma',
    who_to_tip: 'Kam skirti arbatpinigius?',
    payment_type: 'Mokėjimo tipas',
    continue: 'Tęsti',
    summary: 'Santrauka',
    healing_agent: 'Gydomasis agentas',
    tips: 'Arbatpinigiai',
    session_payment: 'Sesijos mokėjimas',
    total: 'Iš viso',
    waiting: 'Laukimas...',
    available_funds: 'Turimos lėšos',
    refill_balance: 'Papildyti balansą',
    go_to_payment: 'Eiti į mokėjimą',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Arbatpinigiai',
    prepayment: 'Avansas',
    have_questions: 'Klausimų?',
    title: 'Sesijos',
    selectStatus: 'Pasirinkite būseną',
    selectParlor: 'Pasirinkite saloną',
    loading: 'Įkeliama...',
    fromDate: 'Nuo datos',
    toDate: 'Iki datos',
    search: 'Ieškoti',
    all: 'Visi',
    status: {
      100: 'Laukiama',
      200: 'Naujas',
      300: 'Vyksta',
      400: 'Užbaigta',
      500: 'Atšaukta',
      600: 'Laukiama apmokėjimo',
    },
  },
  master: {
    rank: 'Rangas',
    works_count: 'Darbų skaičius',
    address: 'Adresas',
  },
};
